import React, { useState } from "react"
import "./get-demo.scss"
import { Link } from "gatsby"
import Image from "../image-component/image-new"
import { Formik, Form, Field, ErrorMessage } from "formik"

export default function GetDemo() {
  const checkWindow = typeof window !== `undefined`
  let email = null
  if (checkWindow) {
    const searchParams = new URLSearchParams(window.location.search)
    email = searchParams.get("email")
      ? decodeURIComponent(searchParams.get("email"))
      : ""
  }

  const [tyVisible, setVisible] = useState(false)

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const [name, setName] = useState("")
  const handleChange = e => {
    setName({ ...name, [e.target.name]: e.target.value })
  }

  const handleSubmit = event => {
    // Prevent the default onSubmit behavior
    event.preventDefault()
    // POST the encoded form with the content-type header that's required for a text submission
    // Note that the header will be different for POSTing a file
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...name,
      }),
    })
      // On success, redirect to the custom success page using Gatsby's `navigate` helper function
      .then(() => {
        setVisible(!tyVisible)
        document.querySelector(".thank-you-message").style.display = "block"
        // window.lintrk('track', { conversion_id: 6913844});
      })
      // On error, show the error in an alert
      .catch(error => alert(error))
  }

  return (
    <>
      <div className="bg-container">
        <div className="form-wrapper">
          <div className="logo-wrap">
            <svg
              width="106"
              height="28"
              viewBox="0 0 106 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.5 5.32821V8.09596L11.8124 2.8655L22.9611 8.13719L22.9002 5.32821L11.8267 0L0.5 5.32821ZM11.8626 22.733L22.9002 28V18.9312L11.9259 13.8433L2.94909 17.9595V15.0834L11.9223 10.7128L22.9002 15.8101V13.1013L11.896 7.83429L0.5 13.1601V22.0523L11.8924 16.717L20.3914 20.8557V23.8778L11.9558 19.8416L0.5 25.0556V28L11.8626 22.733Z"
                fill="#2BA3F7"
              />
              <path
                d="M34.1062 17.8601C34.8635 18.2411 35.6992 18.4461 36.5493 18.4596C37.5624 18.4596 38.0964 18.0462 38.0964 17.4184C38.0964 16.8201 37.6341 16.4762 36.4633 16.0793C34.8433 15.5081 33.7741 14.6248 33.7741 13.2279C33.7741 11.5897 35.1766 10.3495 37.4609 10.3495C38.5743 10.3495 39.3723 10.5627 39.947 10.8335L39.456 12.5731C38.8262 12.269 38.1335 12.1126 37.4322 12.1161C36.4765 12.1161 36.0153 12.5578 36.0153 13.043C36.0153 13.6555 36.5493 13.9264 37.8217 14.3975C39.5432 15.024 40.3376 15.9074 40.3376 17.2618C40.3376 18.873 39.094 20.2404 36.4203 20.2404C35.3069 20.2404 34.2078 19.9412 33.6582 19.6421L34.1062 17.8601Z"
                fill="#2BA3F7"
              />
              <path
                d="M42.29 10.6199C43.2755 10.472 44.2717 10.405 45.2684 10.4197C46.6279 10.4197 47.5956 10.6765 48.2467 11.19C48.8823 11.674 49.2873 12.4725 49.2873 13.4136C49.2873 14.3546 48.9838 15.1661 48.3913 15.6937C47.6398 16.378 46.5264 16.7054 45.2254 16.7054C44.9732 16.7111 44.7211 16.6922 44.4727 16.6489V20.0974H42.29V10.6199ZM44.4727 14.9777C44.7159 15.0236 44.9636 15.0425 45.211 15.0342C46.3818 15.0342 47.1046 14.4453 47.1046 13.4807C47.1046 12.5974 46.4834 12.0674 45.3699 12.0674C45.069 12.0569 44.7679 12.0811 44.4727 12.1392V14.9777Z"
                fill="#2BA3F7"
              />
              <path
                d="M53.3501 10.4919V20.0978H51.1543V10.4919H53.3501Z"
                fill="#2BA3F7"
              />
              <path
                d="M55.7065 10.62C56.6779 10.4792 57.6589 10.4123 58.6407 10.4197C60.0862 10.4197 61.0993 10.6341 61.7922 11.1759C62.3896 11.6317 62.7038 12.316 62.7038 13.1993C62.7038 14.4112 61.8221 15.2521 60.9834 15.5548V15.5972C61.662 15.8681 62.0383 16.4958 62.2844 17.3792C62.5879 18.4768 62.8818 19.7347 63.0646 20.101H60.8102C60.6645 19.816 60.4195 19.0469 60.1448 17.8632C59.87 16.6525 59.4507 16.338 58.5403 16.3239H57.8892V20.101H55.7065V10.62ZM57.8892 14.7527H58.7566C59.8557 14.7527 60.5056 14.211 60.5056 13.3701C60.5056 12.5009 59.8987 12.0451 58.8868 12.0451C58.5526 12.0313 58.218 12.055 57.8892 12.1157V14.7527Z"
                fill="#2BA3F7"
              />
              <path
                d="M67.1281 17.6327L66.4339 20.0978H64.1641L67.1281 10.4919H70.0192L73.056 20.0978H70.6667L69.9152 17.6327H67.1281ZM69.5998 16.0074L68.9929 13.9699C68.8197 13.3987 68.6465 12.6861 68.5019 12.1161H68.4768C68.3323 12.6861 68.1877 13.4116 68.0288 13.9699L67.4506 16.0074H69.5998Z"
                fill="#2BA3F7"
              />
              <path
                d="M74.8086 10.4919H77.0068V18.2734H80.8776V20.0978H74.8086V10.4919Z"
                fill="#2BA3F7"
              />
              <path
                d="M83.5809 20.0978V16.1641L80.501 10.4919H83.0313L84.0145 12.8004C84.318 13.4988 84.5354 14.0123 84.766 14.6389H84.7958C85.0121 14.0406 85.2438 13.4846 85.533 12.8004L86.5162 10.4919H88.988L85.7779 16.0934V20.0978H83.5809Z"
                fill="#2BA3F7"
              />
              <path
                d="M89.8848 18.9295L94.5213 12.3446V12.288H90.3041V10.4919H97.3742V11.7463L92.8344 18.2452V18.3017H97.4471V20.0978H89.8848V18.9295Z"
                fill="#2BA3F7"
              />
              <path
                d="M105.081 16.0357H101.497V18.317H105.5V20.0978H99.2998V10.4919H105.297V12.2727H101.494V14.2749H105.078L105.081 16.0357Z"
                fill="#2BA3F7"
              />
            </svg>
          </div>
          <h1 className="get-demo-heading">Get a Demo</h1>
          <div className="demo-description">
            During the demo we can help you estimate the upside, share some
            day-one test concepts, and answer any questions you may have.
          </div>
          <div className="form-bg-wrap">
            <Formik
              initialValues={{
                name: "",
                email: email ?? "",
                website: "",
              }}
              validate={values => {
                const errors = {}
                const websiteRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
                if (!values.name) {
                  errors.name = "Please enter your name"
                }
                if (!values.email) {
                  errors.email = "Please enter your email address"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "The email you entered doesn’t seem right"
                } else if (
                  [
                    "gmail",
                    "icloud",
                    "yahoo",
                    "outlook",
                    "icloud",
                    "aol",
                    "zoho",
                    "mail",
                    "protonmail",
                    "yandex",
                    "yopmail",
                  ].indexOf(values.email.split("@")[1].split(".")[0]) > -1
                ) {
                  errors.email = "Please enter your business email"
                }
                return errors
              }}
              onSubmit={values => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({
                    "form-name": "get-demo-form",
                    ...values,
                  }),
                })
                  // On success, redirect to the custom success page using Gatsby's `navigate` helper function
                  .then(() => {
                    setTimeout(() => {
                      setVisible(!tyVisible)
                      window.lintrk("track", { conversion_id: 6913844 })
                      document.querySelector(".form-wrapper").style.display =
                        "none"
                      document.querySelector(
                        ".thank-you-message"
                      ).style.display = "block"
                    }, 400)
                  })
                  .catch(error => alert(error))
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form
                  className="form"
                  method="post"
                  name="get-demo-form"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <Field type="hidden" name="bot-field" />
                  <Field type="hidden" name="form-name" value="get-demo-form" />
                  <div className="input-wrap">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={
                        errors.name && touched.name
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <ErrorMessage
                      className="input-error"
                      name="name"
                      component="span"
                    />
                  </div>
                  <div className="input-wrap">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={
                        errors.email && touched.email
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <ErrorMessage
                      className="input-error"
                      name="email"
                      component="span"
                    />
                  </div>
                  <button
                    type="submit"
                    className="submit-btn"
                    disabled={isSubmitting}
                  >
                    Submit -->
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className="thank-you-message get-demo"
          style={{ display: tyVisible ? "block" : "none" }}
        >
          <div className="ty-overlay"></div>
          <div className="ty-message get-demo">
            <Image
              sourceFolder={"get-demo"}
              imgName={"thank-you-image.png"}
              className={"ty-icon"}
              alt={"TY icon"}
            />
            <div className="ty-heading">Thank you for getting in touch!</div>
            <div className="ty-subheading">
              We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon!
            </div>
            <div className="ty-subheading">
              For immediate assistance please call us at{" "}
              <span>888-677-4725</span>
            </div>
            <Link to="/" className="arrow-button">
              Back to main page
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="footer-wrap">
                <div className="copyright-footer">© Spiralyze LLC {new Date().getFullYear()} All Rights Reserved. Terms of Service | Privacy Policy</div>
            </div> */}
    </>
  )
}
