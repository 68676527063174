import * as React from "react"
import SeoNoIndex from "../components/seo-no-index-ablyft"
import GetADemoComponent from "../components/get-demo-new/get-demo"

const GetADemo = () => {
  return (
    <span className="spz-2023">
      <SeoNoIndex title="Get a Demo" index={false} follow={false} />
      <GetADemoComponent />
    </span>
  )
}

export default GetADemo
